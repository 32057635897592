export const environment = {
  production: false,
  env: 'stg',
  securityUrl: 'https://nkzeizuqql.execute-api.us-west-2.amazonaws.com/Stg/api',
  
  clientUrl : 'https://zthdfgknvdw1dev.api.puppis.com.ar/api/v1/',
  
  productUrl: 'https://xagmftjl9c.execute-api.us-west-2.amazonaws.com/Stg/api',
  supplyingUrl: 'https://0rukvmpek6.execute-api.us-west-2.amazonaws.com/Stg',
  omsUrl: 'https://94r1l5s7tc.execute-api.us-west-2.amazonaws.com/Stg/',
  salesUrl: 'https://rxr6xsy6w5.execute-api.us-west-2.amazonaws.com/Stg',
  omsLiteUrl: 'https://5issee36s5.execute-api.us-west-2.amazonaws.com/Stg',
  settingsUrl: 'https://xpaxp43b56.execute-api.us-west-2.amazonaws.com/Stg',
  productsLiteUrl: 'https://p5lifr20ld.execute-api.us-west-2.amazonaws.com/Stg',
  storesUrl: 'https://x6xmnr56va.execute-api.us-west-2.amazonaws.com/Stg',
  maillingUrl: 'https://ohhb5oo2ea.execute-api.us-west-2.amazonaws.com/Dev/api',
  wikiUrl: 'https://qne9jnv9fj.execute-api.us-west-2.amazonaws.com/Dev/api',
  logViewerUrl: 'https://s34fcjctsk.execute-api.us-west-2.amazonaws.com/Stg',
  transfersUrl: 'https://ugh3fwd6lc.execute-api.us-west-2.amazonaws.com/Stg',
  notificationsUrl: 'https://nmao7w1z72.execute-api.us-west-2.amazonaws.com/Stg',
  wmsUrl: 'https://brrcvhaf88.execute-api.us-west-2.amazonaws.com/Stg',
  singleCustomerUrl: 'https://nhos68c3a1.execute-api.us-west-2.amazonaws.com/Stg',
  vetServiceUrl: 'https://qa.puppis.inamika.com/backend',
  puppisRepurchase: "https://iuwfuhu9d6.execute-api.us-west-2.amazonaws.com/Stg",
  AUTH_CONFIG: {
    issuer: 'https://accounts.google.com',
    redirectUri: `${window.location.origin}/login`,
    clientId: '823529633973-h4ponfb7ra82n1256ovqo8bbiobf3pp4.apps.googleusercontent.com',
    scope: 'openid profile email',
    strictDiscoveryDocumentValidation: false,
    secret: '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'
  },
  aws: {
    accessKeyId: 'AKIARP3SLNHXICX6HRMG',
    secretAccessKey: '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',
    region: 'us-west-2',
    supplying: {
      s3: {
        bucket: 'puppis-oms',
        acl: 'public-read'
      }
    }
  }
};
